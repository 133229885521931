import React from 'react';
import PropTypes from 'prop-types';
import './Stack.css';
import clsx from 'clsx';

/**
 * UI component for organizing components along an axis
 */
export const Stack = ({
  children,
  className = '',
  fullY = false,
  fullX = false,
  ratio = false,
  axis = 'x',
  gap = 'md',
  justify = '',
  align = '',
  ...rest
}) => {
  return (
    <div
      {...rest}
      style={{ justifyContent: justify, alignItems: align }}
      className={clsx(
        className,
        'mm-ui-stack',
        axis,
        `gap-${gap}`,
        fullY && 'h-full',
        fullX && 'w-full',
        {
          '[&>:first-child]:w-[38.1%] [&>:last-child]:w-[61.8%]':
            ratio && axis === 'x',
        },
        {
          '[&>:first-child]:h-[38.1%] [&>:last-child]:h-[61.8%]':
            ratio && axis === 'y',
        },
      )}
    >
      {children}
    </div>
  );
};

Stack.propTypes = {
  /**
   * Children, which should be a list of StackFields and Buttons
   */
  children: PropTypes.node,
  /**
   * Layout direction of the child items
   */
  axis: PropTypes.oneOf(['x', 'y']),
  /**
   * Gap between items
   */
  gap: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '4xl']),
  /**
   * Alignment of items on the primary axis
   */
  justify: PropTypes.string,
  /**
   * Alignment of items on the secondary axis
   */
  align: PropTypes.string,
  /**
   * Set the ratio of the columns to Phi.
   */
  ratio: PropTypes.bool,
};

Stack.defaultProps = {};
