import React from 'react';
import clsx from 'clsx';

export function Heading({ className = '', level = 1, ...props }) {
  let Element = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(
        'heading text-2xl/8 font-semibold sm:text-2xl/8 dark:text-white m-0',
        className,
      )}
    />
  );
}

export function Subheading({ className = '', level = 2, ...props }) {
  let Element = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(
        'sub-heading text-base/7 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white',
        className,
      )}
    />
  );
}
