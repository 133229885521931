import React from 'react';
import { clsx } from 'clsx';

/**
 * @param {JSX.IntrinsicElements["img"]} props
 * @param {boolean} [props.rounded]
 */
export function ProfilePicture({ rounded = true, ...rest }) {
  return (
    <img
      {...rest}
      className={clsx(
        'profile-picture flex-none bg-gray-50',
        rounded && 'rounded-full',
      )}
    />
  );
}
