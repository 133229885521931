import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './Card.css';

/**
 *
 */
const styles = {
  color: {
    undefined: 'ring-gray-200 bg-white',
    secondary: 'ring-gray-200 bg-zinc-100',
  },
};

/**
 * UI component for showing data in a card
 *
 * @param {object} props
 * @param {boolean} [props.padded]
 * @param {keyof typeof styles['color']} [props.color]
 * @param {string} [props.className]
 * @param {React.ReactNode} [props.children]
 * @param {React.ReactElement} [props.as]
 */
export const Card = ({
  color = undefined,
  className = undefined,
  children,
  as: As = 'div',
}) => {
  return (
    <As
      className={clsx(
        'ring-stone-200 rounded shadow-md relative grid [grid-template-areas:"header"_"body"_"footer"] [grid-template-rows:max-content_1fr_max-content]',
        styles.color[color],
        className,
      )}
    >
      {children}
    </As>
  );
};

/**
 *
 * @param children
 * @constructor
 */
export const Header = ({ children }) => {
  return (
    <div className="mm-ui-card-header [grid-area:header] px-8 py-4 rounded-t w-full">
      {children}
    </div>
  );
};

export const Body = ({ children }) => {
  return (
    <div className="mm-ui-card-body [grid-area:body] px-8 py-8 rounded-t w-full">
      {children}
    </div>
  );
};

/**
 *
 * @param children
 * @constructor
 */
export const Footer = ({ children }) => {
  return (
    <div className="mm-ui-card-footer [grid-area:footer] bg-gray-50 px-8 py-4 rounded-b w-full overflow-hidden">
      {children}
    </div>
  );
};

Card.propTypes = {
  /**
   * Children, which should be a list of CardFields and Buttons
   */
  children: PropTypes.node,
};

Card.defaultProps = {};
