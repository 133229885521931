import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { Heading } from '~atoms/Heading/Heading';
import { difference, set, union } from '~lib/collections';
import { Notification } from '~organisms/Notification/Notification';
import { ProfileHeader } from '~organisms/ProfileHeader/ProfileHeader';
import { SessionCard } from '~organisms/SessionCard/SessionCard';
import { FetchContext } from 'mm-ui-kit/src/contexts/FetchContext';
import { useContext } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

/**
 *
 */
interface Props {
  user: {
    fullname: string;
    email: string;
  };
  profile: {
    company: string;
    title: string | null;
  };
  event: {
    tracks: object[];
    slug: string;
    setting: {
      enableCalendarInvitesLinks: boolean;
      enableFavoriteSessions: boolean;
    };
  };
  sessions: {
    id: number;
  }[];
}

export default function Show(
  {
    user,
    profile,
    sessions,
    event,
  }: Props,
) {
  useSignals();
  const { fetchJson } = useContext(FetchContext);

  const favorites = useSWR(
    [`/events/${event.slug}/favorites.json`, fetchJson],
    ([path, fetchJson]) => fetchJson<number[]>(path).then(set),
    {
      fallbackData: set<number>([]),
    },
  );

  const toggleFavorite = useSWRMutation(
    [`/events/${event.slug}/favorites/toggle.json`, fetchJson],
    ([path, fetchJson], { arg }: { arg: number }) => fetchJson(path, { schedulable_id: arg }),
  );

  const askQuestion = useSWRMutation(
    [`/events/${event.slug}/sessions/:sessionId/questions`, fetchJson],
    (
      [path, fetchJson],
      { arg }: { arg: { sessionId: number; question: string } },
    ) =>
      fetchJson(path.replace(':sessionId', arg.sessionId.toString()), { question: { question: arg.question } }),
  );

  const open = useSignal(false);

  return (
    <div className="mt-20 mx-[100px] grid grid-cols-10 gap-8 px-4 sm:px-0">
      <Notification show={open} />
      <div className="col-span-10">
        <ProfileHeader
          imageUrl="https://images.unsplash.com/photo-1480497490787-505ec076689f?q=80&w=2500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          name={user.fullname}
          title={profile.title || ''}
          company={profile.company}
          email={user.email}
        />
      </div>
      <div className="col-span-10">
        <Heading className="sm:text-2xl mb-4" level={2}>Sessions</Heading>
        <div className="grid grid-cols-1 auto-rows-max md:grid-cols-2 lg:grid-cols-3 gap-8">
          {sessions.map(session => (
            <SessionCard
              key={session.id}
              session={session}
              features={{
                favorites: event.setting.enableFavoriteSessions,
                addToCalendar: event.setting.enableCalendarInvitesLinks,
              }}
              favorited={favorites.data.has(session.id)}
              onToggleFavorite={async (favorited) => {
                await favorites.mutate(
                  favorited
                    ? union(favorites.data, [session.id])
                    : difference(favorites.data, [session.id]),
                  { revalidate: false },
                );

                toggleFavorite.trigger(session.id);
              }}
              onAskQuestion={question => askQuestion.trigger({ question, sessionId: session.id })}
            />
          ),
          )}
        </div>
      </div>
    </div>
  );
}
