import React from 'react';
import { clsx } from 'clsx';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import {
  stackedListDetails,
  stackedListEmpty,
  stackedListExpand,
  stackedListExpanded,
  stackedListItem,
} from './StackedList.module.css';
import { useSignals } from '@preact/signals-react/runtime';
import { wrapSignal } from '../../../lib/signals.js';

/**
 * @param {object} props
 * @param {unknown} [props.className]
 * @param {import("react").ReactNode} [props.children]
 */
export function StackedList({ className, children }) {
  return (
    <ul role="list" className={clsx('divide-y divide-gray-100', className)}>
      {children}
    </ul>
  );
}

/**
 * @param {object} props
 * @param {string} [props.href]
 * @param {() => unknown} [props.onClick]
 * @Param {import("@preact/signals-react").Signal<boolean>} [props.expanded]
 * @param {import("react").ReactNode} [props.children]
 */
export function StackedListItem({ expanded, onClick = undefined, children }) {
  const expandedInternal = wrapSignal(expanded, false);

  return (
    <li
      className={clsx(
        stackedListItem,
        'relative grid gap-x-6 p-5 overflow-hidden transition-colors',
        expandedInternal.value && stackedListExpanded,
        expanded.value ? 'bg-gray-200/20' : 'bg-transparent',
      )}
    >
      {children}
      <div className="[grid-area:action] grid place-items-center">
        <span
          onClick={onClick}
          className="select-none p-2 hover:bg-brand-50 rounded-full cursor-pointer"
        >
          {expanded.value ? (
            <ChevronDownIcon className="w-4 h-4" />
          ) : (
            <ChevronRightIcon className="w-4 h-4" />
          )}
        </span>
      </div>
    </li>
  );
}

/**
 * @param {object} props
 * @param {import("react").ReactNode} [children]
 */
export function StackedListEmpty({ children = undefined }) {
  return (
    <li
      className={clsx(
        stackedListEmpty,
        'border-gray-100 border rounded-sm px-8 py-6 text-center text-gray-200',
      )}
    >
      {children}
    </li>
  );
}

/**
 * @param {object} props
 * @param {import("react").ReactNode} [props.children]
 */
export function Details({ children }) {
  return <div className={stackedListDetails}>{children}</div>;
}

/**
 * @param {object} props
 * @param {import("react").ReactNode} [props.children]
 */
export function Expand({ children }) {
  useSignals();

  return <div className={stackedListExpand}>{children}</div>;
}
