import React from 'react';
import PropTypes from 'prop-types';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import {
  CalendarDaysIcon,
  CalendarIcon,
  ClockIcon,
  FolderArrowDownIcon,
  HandRaisedIcon,
  MapPinIcon,
  PlayCircleIcon,
  PresentationChartLineIcon,
  StarIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';

import { Badge } from '@atoms/Badge/Badge';
import { Button } from '@atoms/Button/Button.jsx';
import { Label } from '@atoms/Label/Label';
import { Link } from '@atoms/Link/Link';
import { Stack } from '@organisms/Stack/Stack';
import { AskAQuestionDialog } from '@organisms/AskAQuestionDialog/AskAQuestionDialog.jsx';
import { Card, Footer, Header } from '@organisms/Card/Card';
import { Heading } from '@atoms/Heading/Heading';

import './SessionCard.css';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from '@molecules/Dropdown/Dropdown.jsx';

/**
 * UI component for showing data in a card
 *
 * @param {object} props
 * @param {object} props.session
 * @param {{ favorites?: boolean; addToCalendar?: boolean }} props.features
 * @param {boolean} [props.favorited]
 * @param {(favorited: boolean) => unknown} [props.onToggleFavorite]
 * @param {(question: string) => unknown} [props.onAskQuestion]
 */
export const SessionCard = ({
  session,
  features,
  favorited = false,
  onToggleFavorite = () => {},
  onAskQuestion = () => {},
}) => {
  useSignals();

  const askingQuestion = useSignal(false);

  return (
    <Card>
      <Header>
        <Stack axis="y" gap="sm">
          <Stack justify="space-between" align="center" fullX>
            <Heading level={3} className="mb-0 sm:text-lg">
              <Link href={session.url}>{session.title}</Link>
            </Heading>
            <Stack>
              {session.roles.map((role) => (
                <Badge
                  key={role}
                  className="sm:text-sm whitespace-nowrap"
                  color="brand"
                >
                  <UserIcon className="w-4 h-4" />
                  {role}
                </Badge>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Header>

      <Footer>
        <Stack axis="y" gap="sm">
          <Label icon={CalendarDaysIcon} className="shrink-0">
            On <span className="text-gray-600">{session.presentingDay}</span>
          </Label>
          <Label icon={ClockIcon} className="shrink-0">
            At <span className="text-gray-600">{session.presentingTime}</span>
          </Label>
          <Label icon={MapPinIcon} tooltip={session.name}>
            In <span className="text-gray-600">{session.room.name}</span>
          </Label>
        </Stack>
        <div className="bg-white -mx-8 -mb-4 mt-4 px-4 lg:px-8 py-4">
          <Stack gap="none" className="text-xl" justify="space-between">
            {features.addToCalendar && (
              <Dropdown>
                <DropdownButton plain>
                  <CalendarIcon />
                </DropdownButton>
                <DropdownMenu
                  anchor="bottom start"
                  className="[&_a:hover]:text-white [&_a:hover]:no-underline"
                >
                  <DropdownItem>
                    <a href={session.calendarUrls.apple}>
                      Add to Apple Calendar or Download ICS File
                    </a>
                  </DropdownItem>
                  <DropdownItem>
                    <a href={session.calendarUrls.google}>
                      Add to Google Calendar
                    </a>
                  </DropdownItem>
                  <DropdownItem>
                    <a href={session.calendarUrls.outlook}>
                      Add to Outlook Calendar
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
            {features.favorites && (
              <Button plain onClick={() => onToggleFavorite(!favorited)}>
                {favorited ? (
                  <StarIconSolid className="!text-brand-500" />
                ) : (
                  <StarIcon title="Favorite session" />
                )}
              </Button>
            )}
            <Button
              plain
              disabled={!session.webcastUrl}
              href={session.webcastUrl}
            >
              <PlayCircleIcon />
            </Button>
            <Button
              plain
              disabled={!session.askAQuestion}
              onClick={() => (askingQuestion.value = true)}
            >
              <HandRaisedIcon />
            </Button>
            <Button
              plain
              disabled={!session.slidesUrl}
              href={session.slidesUrl}
            >
              <PresentationChartLineIcon />
            </Button>
            <Button plain disabled={!session.downloadsUrl}>
              <FolderArrowDownIcon />
            </Button>
          </Stack>
        </div>
      </Footer>
      <AskAQuestionDialog
        session={session}
        open={askingQuestion}
        onSubmit={onAskQuestion}
      />
    </Card>
  );
};

SessionCard.propTypes = {
  /**
   * Children, which should be a list of CardFields and Buttons
   */
  children: PropTypes.node,
};

SessionCard.defaultProps = {};
