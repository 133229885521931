import React, { useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

/**
 *
 * @param props {object}
 * @param props.lines {number}
 * @param props.children {import("react").ReactNode}
 * @param props.as {import("react").ReactNode}
 * @param [props.suffix] {string}
 * @param [props.split] {string|RegExp}
 * @param [props.renderReadMore] {() => import("react").ReactElement}
 * @return {import("react").ReactElement}
 */
export function ClampedText({
  lines,
  children,
  suffix = '...',
  split = /[\s.:;,]+/g,
  renderReadMore = undefined,
  ...props
}) {
  useSignals();

  const containerRef = useRef(/** @type {HTMLElement|null} */ null);
  const originalRef = useRef(/** @type {HTMLElement|null} */ null);
  const testRef = useRef(/** @type {HTMLElement|null} */ null);
  const html = useSignal('');
  const clamped = useSignal(false);

  const reflowLines = useCallback(() => {
    if (!originalRef.current || !testRef.current) return;

    // Clone the original so we have something with which to test the number of lines in the container.
    const contents = originalRef.current.cloneNode(true);
    testRef.current.append(contents);

    const suffixNode = document.createTextNode(suffix);

    // Get all the text nodes in the tree
    const textNodes = [contents, ...(contents.querySelectorAll('*') || [])]
      .flatMap((element) => [...element.childNodes])
      .filter((node) => node.nodeType === 3)
      .reverse();

    let textNode;

    while (([textNode] = textNodes)) {
      const styles = getComputedStyle(originalRef.current);
      const { height } = contents.getBoundingClientRect();
      const lineHeight = parseInt(styles.lineHeight);
      const currentLines = Math.floor(height / lineHeight);

      if (currentLines <= lines) break;

      if (!textNode.textContent) {
        textNode.remove();
        textNodes.shift();
        continue;
      }

      const [{ index }] = [...textNode.textContent.matchAll(split)].reverse();
      textNode.textContent = textNode.textContent.substring(0, index);

      if (!suffixNode.parentNode) contents.appendChild(suffixNode);
    }

    clamped.value = contents.innerHTML !== originalRef.current.innerHTML;

    html.value = contents.innerHTML;
    contents.remove();
  }, [originalRef.current, testRef.current, lines, split]);

  useEffect(() => {
    if (!containerRef.current) return;

    const observer = new ResizeObserver(reflowLines);
    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [reflowLines, containerRef.current]);

  useEffect(() => reflowLines(), [reflowLines]);

  return (
    <props.as ref={containerRef} className={clsx('relative')}>
      <props.as dangerouslySetInnerHTML={{ __html: html.value }} />
      {clamped.value && renderReadMore?.()}
      <div
        ref={originalRef}
        aria-hidden
        aria-disabled
        className="absolute pointer-events-none inset-x-0 invisible select-none"
      >
        {children}
      </div>
      <div
        ref={testRef}
        aria-hidden
        aria-disabled
        className="absolute pointer-events-none inset-x-0 invisible select-none"
      ></div>
    </props.as>
  );
}
