// JsFromRoutes CacheKey 1bebefaeb2c3d8fcf337cf85135fd594
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/participants'),
  search: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/users/search'),
  twoFactor: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/users/:id/two_factor'),
  confirmTwoFactor: /* #__PURE__ */ definePathHelper('put', '/events/:event_id/users/:id/confirm_two_factor'),
  qrCode: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/users/:id/qr_code'),
  create: /* #__PURE__ */ definePathHelper('post', '/events/:event_id/users'),
  new: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/users/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/users/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/users/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/events/:event_id/users/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/events/:event_id/users/:id'),
}
