import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from '@organisms/Dialog/Dialog.jsx';
import { Textarea } from '@atoms/Textarea/Textarea.jsx';
import { InformationCircleIcon } from '@heroicons/react/24/outline/index.js';
import { Button } from '@atoms/Button/Button.jsx';
import React, { useEffect } from 'react';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';

/**
 *
 * @param {import("@preact/signals-react").Signal<Boolean>} open
 * @param {object} session
 * @param {(question: string) => unknown} onSubmit
 * @returns {Element}
 * @constructor
 */
export const AskAQuestionDialog = ({ open, session, onSubmit }) => {
  useSignals();

  const question = useSignal('');

  useEffect(() => {
    if (open.value) question.value = '';
  }, [open.value]);

  return (
    <Dialog open={open.value} onClose={() => (open.value = false)}>
      <DialogTitle>Ask a Question - {session.title}</DialogTitle>
      <DialogBody>
        <Textarea
          rows={5}
          placeholder="Question"
          onInput={(e) => (question.value = e.target.value)}
        />
        <p className="text-gray-400 text-xs mt-4">
          <InformationCircleIcon className="w-4 h-4 mr-2 inline" />
          Questions asked may be seen by Research Teams.
        </p>
      </DialogBody>
      <DialogActions>
        <Button color="white" onClick={() => (open.value = false)}>
          Cancel
        </Button>
        <Button
          color="brand"
          disabled={!question.value}
          onClick={() => {
            onSubmit(question.value);
            open.value = false;
          }}
        >
          Submit Question
        </Button>
      </DialogActions>
    </Dialog>
  );
};
