import { useSignal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import SessionsApi from '~api/SessionsApi';
import UsersApi from '~api/UsersApi';
import { Badge } from '~atoms/Badge/Badge';
import { ClampedText } from '~atoms/ClampedText/ClampedText';
import { Heading, Subheading } from '~atoms/Heading/Heading';
import { ProfilePicture } from '~atoms/ProfilePicture/ProfilePicture';
import { set, sortBy, unique } from '~lib/collections';
import { prop } from '~lib/functors';
import {
  Listbox,
  ListboxLabel,
  ListboxOption,
} from '~molecules/Listbox/Listbox';
import { Stack } from '~organisms/Stack/Stack';
import {
  Details,
  Expand,
  StackedList,
  StackedListEmpty,
  StackedListItem,
} from '~organisms/StackedList/StackedList';
import { Tab, Tabs } from '~organisms/Tabs/Tabs';
import { clsx } from 'clsx';
import { useCallback, useMemo } from 'react';

/**
 *
 */
export interface Props {
  event: {
    slug: string;
  };
  participants: {
    id: number;
    fullname: string;
    lastName: string;
    company: string;
    email: string;
    bio: string | null;
    profiles: {
      bio: string | null;
    }[];
  }[];
  participations: {
    userId: number;
    role: {
      id: number;
      name: string;
    };
    participationable: {
      id: number;
      title: string;
    };
  }[];
}

function ParticipantListItem({
  event,
  user,
  participations,
}: { event: Props['event']; user: Props['participants'][number]; participations: Props['participations'] }) {
  useSignals();

  const open = useSignal(false);

  return (
    <StackedListItem
      key={user.id}
      onClick={() => (open.value = !open.value)}
      expanded={open}
    >
      <ProfilePicture src="https://images.unsplash.com/photo-1455156218388-5e61b526818b?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
      <Heading>
        <a href={UsersApi.show.path({ event_id: event.slug, id: user.id })}>
          {user.fullname}
        </a>
      </Heading>
      {user.email && <Subheading>{user.email}</Subheading>}

      <Details>
        <Stack axis="y" gap="xs" align="flex-end">
          <span>{user.company || 'N/A'}</span>

          <Stack gap="xs">
            {participations.map(item => (
              <Badge key={item.role.name} color="brand">
                {item.role.name}
              </Badge>
            ))}
          </Stack>
        </Stack>
      </Details>
      <Expand>
        <Stack axis="y">
          <div>
            <Heading level={4} className="p-0 mb-2 uppercase !text-sm">
              Bio
            </Heading>
            <ClampedText
              lines={2}
              as="div"
              renderReadMore={() => (
                <p className="mt-2">
                  <a href={UsersApi.show.path({ event_id: event.slug, id: user.id })}>Read more</a>
                </p>
              )}
            >
              {user.bio || user.profiles[0].bio
                ? (
                    user.bio || user.profiles[0].bio
                  )
                : (
                    <span className="opacity-50">Not available</span>
                  )}
            </ClampedText>
          </div>

          <div>
            <Heading level={4} className="p-0 mb-2 uppercase !text-sm">
              Sessions
            </Heading>
            <Stack axis="y" gap="xs">
              {sortBy(
                participations,
                item => item.participationable.title,
              ).map(item => (
                <a
                  key={item.participationable.id}
                  href={SessionsApi.show.path({
                    event_id: event.slug,
                    ...item.participationable,
                  })}
                >
                  {item.participationable.title}
                </a>
              ))}
            </Stack>
          </div>
        </Stack>
      </Expand>
    </StackedListItem>
  );
}

/**
 *
 */
export default function Index(
  {
    event,
    participants,
    participations,
  }: Props,
) {
  useSignals();

  const currentTab = useSignal('All');
  const selectedRole = useSignal('any');

  /**
   * @param user
   */
  const firstLetter = useCallback(
    (user: Props['participants'][number]) => user.lastName[0].toUpperCase(),
    [],
  );

  const letters = useMemo(
    () => [...set(participants.map(firstLetter).sort())],
    [participants],
  );

  const roles = useMemo(
    () => unique(participations.map(prop('role')), item => item.name),
    [participations],
  );

  const participationsForUser = useMemo(
    () => {
      const lookup = new Map(
        participants.map(item => [item.id, [] as Props['participations']]),
      );

      for (const participation of participations) {
        lookup.get(participation.userId)?.push(participation);
      }

      return lookup;
    },
    [participants, participations],
  );

  const filteredParticipants = useMemo(
    () =>
      participants
        .filter(user =>
          currentTab.value === 'All' || user.lastName.toUpperCase().startsWith(currentTab.value),
        )
        .filter(item =>
          selectedRole.value === 'any' || participationsForUser
            .get(item.id)
            ?.find(participation => participation.role.id.toString() === selectedRole.value),
        )
        .sort((first, second) =>
          first
            .lastName
            .localeCompare(second.lastName, undefined, { sensitivity: 'base' }),
        ),
    [participants, selectedRole.value, currentTab.value, participationsForUser],
  );

  /**
   *
   */
  const renderParticipants = useCallback(
    () => (
      <StackedList>
        <StackedListEmpty>No users</StackedListEmpty>

        {filteredParticipants.map(user => (
          <ParticipantListItem
            key={user.id}
            user={user}
            event={event}
            participations={participationsForUser.get(user.id) || []}
          />
        ))}
      </StackedList>
    ),
    [filteredParticipants],
  );

  return (
    <div className="mt-20 flex min-h-[600px] mx-[100px]">
      <Stack fullX>
        <div className={clsx([
          'relative pr-8 after:content-[\'_\'] after:left-full after:top-0 after:bottom-0 after:absolute after:w-16',

          // Right shadow
          'after:bg-gradient-to-r after:from-brand-50 after:[mask:linear-gradient(0deg,transparent,black,transparent)]',
          'after:content-[\'_\'] after:left-full after:top-0 after:bottom-0 after:absolute after:w-8',
        ])}
        >
          <Listbox className="w-64" placeholder="Filter by role" value={selectedRole}>
            <ListboxOption value="any">
              <ListboxLabel>Any role</ListboxLabel>
            </ListboxOption>
            {roles.map(role => (
              <ListboxOption value={role.id.toString()} key={role.id}>
                <ListboxLabel>
                  {role.name}
                </ListboxLabel>
              </ListboxOption>
            ))}
          </Listbox>
        </div>
        <div className="grow pl-8">
          <Tabs pills current={currentTab} buttonsClassName="[grid-template-columns:repeat(9,1fr)] gap-4 mb-8">
            <Tab name="All">
              {() => renderParticipants()}
            </Tab>
            {letters.map(letter => (
              <Tab key={letter} name={letter}>
                {() => renderParticipants()}
              </Tab>
            ))}
          </Tabs>
        </div>
      </Stack>
    </div>
  );
}
