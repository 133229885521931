import React from 'react';
import clsx from 'clsx';

import { Stack } from '@organisms/Stack/Stack.jsx';
import { Heading } from '@atoms/Heading/Heading.jsx';
import { Label } from '@atoms/Label/Label.jsx';
import { EnvelopeIcon } from '@heroicons/react/24/outline/index.js';

/**
 *
 * @param {object} props
 * @param {string} props.imageUrl
 * @param {string} props.name
 * @param {string} props.company
 * @param {string} props.email
 * @param {string} [props.title]
 * @param {unknown} [props.className]
 */
export const ProfileHeader = ({
  imageUrl = '',
  name = '',
  company = '',
  email = '',
  title = undefined,
  className = undefined,
}) => {
  return (
    <div className="relative mb-12 pt-12">
      <div className="absolute w-full rounded h-full top-0 bg-gradient-to-r from-transparent via-gray-200/75 [mask:linear-gradient(black,transparent)]"></div>
      <div className={clsx('relative grid grid-cols-10 gap-8', className)}>
        <div className="col-span-10 md:col-span-4 lg:col-span-2 flex justify-center md:justify-start">
          <div className="p-2 bg-white shadow-lg rounded-full w-full max-w-52">
            <img className="aspect-square rounded-full" src={imageUrl} />
          </div>
        </div>
        <div className="col-span-10 md:col-span-6 lg:col-span-8 content-center">
          <Stack
            axis="y"
            gap="sm"
            className="items-center md:items-end lg:items-start"
          >
            <Stack
              align="center"
              gap="none"
              className="flex-col md:flex-row md:divide-x-2"
            >
              <Heading
                level={1}
                className="text-4xl/none sm:text-4xl/none mb-0 md:pr-2 text-right"
              >
                {name}
              </Heading>
              <Heading
                level={2}
                className="text-2xl/none sm:text-2xl/none text-gray-300 mb-0 md:pl-2"
              >
                {title && <>{title}, </>}
                {company}
              </Heading>
            </Stack>
            <Stack>
              <Label icon={EnvelopeIcon} className="text-lg">
                {email}
              </Label>
            </Stack>
          </Stack>
        </div>
      </div>
    </div>
  );
};
