/**
 *
 */
export type MaybeSet<T> = T[] | Set<T>;

/**
 *
 */
export const set = <T>(array: MaybeSet<T>) => new Set(array);

/**
 *
 * @param left
 * @param right
 */
export function difference<L, R>(left: MaybeSet<L>, right: MaybeSet<R>) {
  return set(left).difference(set(right));
}

/**
 *
 * @param left
 * @param right
 */
export function union<L, R>(left: MaybeSet<L>, right: MaybeSet<R>) {
  return set(left).union(set(right));
}

/**
 *
 * @param items
 * @param by
 */
export function uniqueWeak<L, R extends WeakKey>(items: L[], by: (item: L) => R) {
  const lookup = new WeakMap<R, L>();

  items.forEach((item) => {
    const key = by(item);

    if (!lookup.has(key))
      lookup.set(key, item);
  });

  return [...items.values()];
}

/**
 *
 * @param items
 * @param by
 */
export function unique<T, R>(items: T[], by: (item: T) => R) {
  return [
    ...new Map<R, T>(items.reverse().map(item => [by(item), item])).values(),
  ];
}

export function hasMany<
  Source,
  Target,
  PrimaryKey extends keyof Source,
  ForeignKey extends { [K in keyof Target]: Target[K] extends Source[PrimaryKey] ? K : never }[keyof Target],
>(left: Source[], right: Target[], primaryKey: PrimaryKey, foreignKey: ForeignKey) {
  const lookup = new Map(
    left.map(item => [item[primaryKey], [] as Target[]]),
  );

  for (const item of right) {
    lookup.get(item[foreignKey] as unknown as Source[PrimaryKey])?.push(item);
  }

  return lookup;
}

export function sortBy<T, R>(items: T[], get: (item: T) => R) {
  return items.toSorted((left, right) => {
    const leftValue = get(left);
    const rightValue = get(right);

    return typeof leftValue === 'string' && typeof rightValue === 'string'
      ? leftValue.localeCompare(rightValue)
      : Number(leftValue) - Number(rightValue);
  });
}
