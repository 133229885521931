import React, { createContext, useContext, useEffect } from 'react';
import { clsx } from 'clsx';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';

const TabContext = createContext({
  tabs: {
    value:
      /** @type {{ name: string, disabled?: boolean, icon?: import("react").ReactElement }[]} */ [],
  },
  current: { value: '' },
  pills: false,
});

/**
 *
 * @param {object} props
 * @param {boolean} [props.pills]
 * @param {unknown} [props.buttonsClassName]
 * @param {import("@preact/signals-react").Signal<string|undefined>} props.current
 * @param {import("react").ReactNode} [props.children]
 * @returns {JSX.Element}
 */
export function Tabs({
  current,
  pills,
  children,
  buttonsClassName = undefined,
}) {
  useSignals();

  const tabs = useSignal(
    /** @type {{ name: string, disabled?: boolean, icon?: import("react").ReactElement }[]} */ [],
  );

  useEffect(() => {
    current.value ||= window.location.hash.substring(1);
  }, []);

  useEffect(() => {
    window.location.hash = current.value;
  }, [current.value]);

  return (
    <TabContext.Provider value={{ current, tabs, pills }}>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={current.value}
            className="block w-full rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500"
          >
            {tabs.value.map(({ name, disabled }) => (
              <option key={name} disabled={disabled}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:!block">
          <div className={clsx(!pills && 'border-b border-gray-200')}>
            <nav
              aria-label="Tabs"
              className={clsx(
                'grid *:no-underline *:uppercase *:group *:inline-flex *:items-center text-sm [&>:disabled]:text-gray-200',
                ...(pills
                  ? [
                      '*:transition-all [&>:hover]:bg-gray-100',
                      '[&>[data-current=true]]:text-brand-500 [&>[data-current=true]]:bg-brand-50',
                      '*:text-gray-500 *:hover:text-gray-700 *:rounded-md *:px-3 *:py-2 *:font-medium',
                    ]
                  : [
                      '-mb-px [&>[data-current=true]]:border-brand-500 [&>[data-current=true]]:text-brand-600',
                      '*:border-transparent *:text-gray-500 [&>[data-current=false]:not(:disabled):hover]:border-gray-300 [&>[data-current=false]:not(:disabled):hover]:text-gray-700',
                      '*:border-b-2 *:px-1 *:py-4 *:font-medium',
                    ]),
                buttonsClassName,
              )}
            >
              {tabs.value.map(({ name, disabled, icon: Icon }) => (
                <button
                  key={name}
                  type="button"
                  aria-current={current.value === name ? 'page' : undefined}
                  onClick={() => {
                    current.value = name;
                  }}
                  data-current={current.value === name}
                  disabled={disabled}
                  className="tab disabled:cursor-default"
                >
                  {Icon && (
                    <Icon
                      aria-hidden="true"
                      className={clsx(
                        current.value === name
                          ? 'text-brand-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        '-ml-0.5 mr-2 h-5 w-5',
                      )}
                    />
                  )}
                  <span>{name}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </TabContext.Provider>
  );
}

/**
 * @param {object} props
 * @param {string} props.name
 * @param {boolean} [props.disabled]
 * @param {import('react').ReactElement} [props.icon]
 * @param {import('react').ReactNode|Function} [props.children]
 */
export function Tab({
  name,
  icon = undefined,
  disabled = false,
  children = undefined,
}) {
  useSignals();

  const { tabs, current } = useContext(TabContext);

  useEffect(() => {
    tabs.value = [...tabs.value, { name, icon, disabled }];
  }, []);

  return current.value === name ? (
    <>{typeof children === 'function' ? children() : children}</>
  ) : null;
}

/**
 * @param {object} props
 * @param {unknown} [props.className]
 * @param {import('react').ReactNode} [props.children]
 */
export function TabsContainer({ children }) {
  return children;
}
