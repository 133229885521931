import React from 'react';
import PropTypes from 'prop-types';
import './Label.css';
import { clsx } from 'clsx';

/**
 * UI component for labeling input fields
 */
export const Label = ({
  children,
  type,
  tooltip = undefined,
  icon: Icon = undefined,
  className = undefined,
  ...props
}) => {
  return (
    <label
      className={clsx(
        'mm-ui mm-ui-label text-sm inline-flex items-center overflow-hidden',
        type,
        className,
      )}
      {...props}
      data-tip={tooltip}
    >
      {Icon && (
        <span className="mr-2">
          <Icon className="w-4 h-4 inline text-gray-500" />
        </span>
      )}
      <span className="text-gray-500/75 whitespace-nowrap text-ellipsis overflow-hidden ">
        {children}
      </span>
    </label>
  );
};

Label.propTypes = {
  /**
   * Text
   */
  children: PropTypes.node,
  /**
   * The type of the label
   */
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'default',
  ]),
};

Label.defaultProps = {
  label: 'default',
  text: '',
};
