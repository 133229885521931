import React, { createContext, useCallback, useMemo } from 'react';

/**
 * @param {string} [csrfToken]
 * @constructor
 */
export const FetchContext = createContext({
  fetch: window.fetch,
  fetchJson:
    /**
     * @template T
     * @param {string|URL|Request|RequestInfo} _resource
     * @param {object} [_body]
     * @param {RequestInit} [_options]
     * @returns {Promise<T>}
     */
    (_resource, _body, _options = {}) =>
      Promise.reject('FetchContext not configured'),
});

/**
 * @param {ReactNode} children
 * @param {string} [csrfToken]
 * @returns {JSX.Element}
 */
export const FetchProvider = ({ children, csrfToken = undefined }) => {
  const token = useMemo(
    () =>
      csrfToken || document.querySelector('meta[name="csrf-token"]').content,
    [csrfToken],
  );

  const fetch = useCallback(
    /**
     * @param {string|URL|Request|RequestInfo} resource
     * @param {RequestInit} [options]
     * @returns {Promise<Response>}
     */
    (resource, options = {}) =>
      window.fetch(resource, {
        ...options,
        headers: {
          ...options.headers,
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token,
        },
      }),
    [token],
  );

  const fetchJson = useCallback(
    /**
     * @param {string|URL|Request|RequestInfo} resource
     * @param {object} [body]
     * @param {RequestInit} [options]
     * @returns {Promise<Response>}
     */
    async (resource, body = undefined, options = {}) =>
      await fetch(resource, {
        ...options,
        method: options.method || (body ? 'POST' : 'GET'),
        body: body && JSON.stringify(body),
        headers: {
          ...options.headers,
          'Content-Type': 'application/json',
        },
      }).then((r) => r.json()),
    [fetch],
  );

  return (
    <FetchContext.Provider value={{ fetch, fetchJson }}>
      {children}
    </FetchContext.Provider>
  );
};
