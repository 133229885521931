import { useSignal } from '@preact/signals-react';
import { useEffect } from 'react';

/**
 * @template T
 * @param {import("@preact/signals-react").Signal<T>} signal
 * @param {T} [fallbackInitial]
 */
export const wrapSignal = (signal = undefined, fallbackInitial = undefined) => {
  const internal = useSignal(
    /** @type {T|undefined} */ (signal?.value || fallbackInitial),
  );

  useEffect(() => {
    internal.value = signal?.value;
  }, [signal?.value]);

  useEffect(() => {
    if (signal) signal.value = internal.value;
  }, [signal, internal.value]);

  return internal;
};
