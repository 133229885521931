// JsFromRoutes CacheKey 6149b221bb435fb113d0797640d5d143
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  dashboard: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/dashboard'),
  downloadFile: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/download_file'),
  circle: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/circle'),
  bio: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/bio'),
  presentationSlidesReview: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/presentation_slides_review'),
  informationForPresentation: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/information_for_presentation'),
  informationForOneOnOneParticipants: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/information_for_one_on_one_participants'),
  presentationElements: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/presentation_elements'),
  downloadableElements: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/downloadable_elements'),
  presentationSlides: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/presentation_slides'),
  notes: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/notes'),
  agendaAndSignage: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/agenda_and_signage'),
  vcrTestDetail: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/vcr_test_detail'),
  voteSurvey: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/vote_survey'),
  addTestDetails: /* #__PURE__ */ definePathHelper('post', '/events/:event_id/sessions/:id/add_test_details'),
  room: /* #__PURE__ */ definePathHelper('put', '/events/:event_id/sessions/:id/room'),
  signinGpu: /* #__PURE__ */ definePathHelper('post', '/events/:event_id/sessions/:id/signin_gpu'),
  generalSignin: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/general_signin'),
  signupGpu: /* #__PURE__ */ definePathHelper('post', '/events/:event_id/sessions/:id/general_signin'),
  search: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/search'),
  index: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions'),
  create: /* #__PURE__ */ definePathHelper('post', '/events/:event_id/sessions'),
  new: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/events/:event_id/sessions/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/events/:event_id/sessions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/events/:event_id/sessions/:id'),
}
